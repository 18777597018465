$c-primary: #CDB380;
$c-secandary: #CDB380;
$dots: false;
$version: 3.1;
$body-color-overrided: #002B4A;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'NHaasGroteskTXPro', sans-serif;
$font-family-heading: 'NHaasGroteskTXPro', sans-serif;

      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BornReadySlanted';
        src: url('/fonts/BornReadySlanted-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  font-weight: 300;

  .content {
    margin-top: 25px;

    h3 {
      font-size: 19px;
      font-weight: 300;
    }
  }

  img {
    margin-top: 25px;
    width: 100%;
    max-width: 190px;
    border-radius: 10px;
  }
}

@media (max-height: 625px) {
  .wrapper {
    .content {
      margin-top: 10px;
    }

    img {
      max-width: 150px;
    }
  }
}

@media (max-height: 545px) {
  .wrapper {
    .content {
      margin-top: -20px;
    }

    img {
      max-width: 140px;
    }
  }
}
