$c-primary: #CDB380;
$c-secandary: #CDB380;
$dots: false;
$version: 3.1;
$body-color-overrided: #002B4A;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'NHaasGroteskTXPro', sans-serif;
$font-family-heading: 'NHaasGroteskTXPro', sans-serif;

      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BornReadySlanted';
        src: url('/fonts/BornReadySlanted-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
}
