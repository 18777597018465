$c-primary: #CDB380;
$c-secandary: #CDB380;
$dots: false;
$version: 3.1;
$body-color-overrided: #002B4A;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'NHaasGroteskTXPro', sans-serif;
$font-family-heading: 'NHaasGroteskTXPro', sans-serif;

      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BornReadySlanted';
        src: url('/fonts/BornReadySlanted-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);

  .heading {
    font-family: var(--heading-font-family) !important;
    color: var(--heading-text-color) !important;
    margin-bottom: 1.5rem; //24
    text-align: left;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
  }

  .description {
    text-align: left;
  }
}
