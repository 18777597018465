$c-primary: #CDB380;
$c-secandary: #CDB380;
$dots: false;
$version: 3.1;
$body-color-overrided: #002B4A;
$progress-bar-overided: #fff;
$progress-bar-incomplete-overided: #000;
$base-font-family: 'NHaasGroteskTXPro', sans-serif;
$font-family-heading: 'NHaasGroteskTXPro', sans-serif;

      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Bold.ttf');
        font-weight: 600;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Medium.ttf');
        font-weight: 500;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'NHaasGroteskTXPro';
        src: url('/fonts/NHaasGroteskTXPro-Regular.ttf');
        font-weight: 400;
        font-style: normal;
      }

    
      @font-face {
        font-family: 'BornReadySlanted';
        src: url('/fonts/BornReadySlanted-Regular.otf');
        font-weight: 400;
        font-style: normal;
      }

    

            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  text-align: left;
  position: absolute;
  top: 35%;
  padding: 2rem;

  .heading {
    font-family: var(--heading-font-family) !important;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
  }

  .title {
    font-size: 14px;
  }

  .img-container {
    img {
      height: 40px;
    }
  }
}
